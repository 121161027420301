import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import Logo from '../assets/clausible_svg.svg';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#ff914d',
  },
  paddingLeft: 40,
}));

const Sidebar = ({ onHomeClick, onActionClick, open }) => {
  return (
    <Box sx={{ height: '100vh', minWidth: open ? '15vw' : 0, transition: 'width 0.3s', marginLeft: 0, paddingRight: 0, borderRight: open ? '4px solid #ff914d' : '0px solid #ff914d' }}>
      {open && (
        <>
          <Box sx={{ mt: 4 }}>
            <List>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <img src={Logo} alt="Logo" />
              </Box>
              <StyledListItemButton onClick={onHomeClick}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </StyledListItemButton>
              <StyledListItemButton onClick={onActionClick}>
                <ListItemIcon>
                  <DirectionsRunIcon />
                </ListItemIcon>
                <ListItemText primary="Actions" />
              </StyledListItemButton>
              <StyledListItemButton>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </StyledListItemButton>
            </List>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Sidebar;
