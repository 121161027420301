import { Box, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const DynamicForm = ({ apiData, onSubmitClick, emptyFields }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({ ...apiData });
  }, [apiData]);

  const handleInputChange = (event, field) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Filter out unchanged or null values from formData
    const updatedData = Object.entries(formData).reduce((acc, [key, value]) => {
      if (value !== null && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
    
    onSubmitClick(updatedData);
  };

  const renderFields = () => {
    return Object.entries(apiData)
    .filter(([key]) => !key.includes('SIGNATURE')) // Exclude SIGNATURE fields
    .map(([key, value]) => {
      const isFilled = value !== '' && value !== null && apiData[key] !== '';
      const hasError = emptyFields.includes(key);
      const isDateField = key === "[SIGNING DATE]";
        return (
          <Box class="form-field config-box--field" key={key}>
            <TextField
              label={key}
              type={isDateField ? 'date' : 'text'}
              value={formData[key] || ''}
              onChange={(e) => handleInputChange(e, key)}
              variant="outlined"
              fullWidth 
              disabled={isFilled}
              error={hasError}
              helperText={hasError ? 'This field must be filled out.' : ''}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>
        );
      });
  };

  const allFieldsFilled = () => {
    return Object.entries(apiData)
      .filter(([key]) => !key.includes('- SIGNATURE')) // Exclude keys containing SIGNATURE
      .every(([, value]) => value !== null && value !== '');
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {renderFields()}
      <Button class="btn btn-submit" type="submit" variant="contained" disabled={allFieldsFilled()}>
        Update Document
      </Button>
    </Box>
  );
};

export default DynamicForm;