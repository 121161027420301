import { Backdrop, Container } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { getAuth, signOut } from 'firebase/auth';
import mammoth from 'mammoth';
import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import EmailVerification from './EmailVerification';
import HomeScreen from './HomeScreen';
import Landing from './Landing';
import Loading from './Loading';
import LoginScreen from './LoginScreen';
import SignUp from './SignUp';
import UploadScreen from './UploadScreen';
import UserCreate from './UserCreate';
import PrivateRoute from './common/PrivateRoute';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  position: 'relative',
});

const BlurContainer = styled('div')(({ blurred }) => ({
  filter: blurred ? 'blur(5px)' : 'none',
  transition: 'filter 0.3s ease',
  height: '100%',
  width: '100%',
  zIndex: 1,
}));

const ModalWrapper = styled('div')({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '800px',
  zIndex: 3,
});

const uploadFileToGCS = async (file, preSignedUrl) => {
  try {
    const response = await axios.put(preSignedUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });
    return response.status === 200;
  } catch (error) {
    console.error('Error uploading to MinIO:', error);
    return false;
  }
};

function Controller() {
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileRejections, setFileRejections] = useState([]);
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState('');
  const [docText, setDocText] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const handleLoginSuccess = async (user) => {
    initializeUser();
  };

  const initializeUser = () => {
    try {
      const storedUserId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const email = localStorage.getItem('email');
      const displayName = localStorage.getItem('displayName');

      if (storedUserId && token) {
        setUser({ id: storedUserId, token: token, displayName: displayName, email: email });
      }
    } catch {
      setUser(null);
    } finally {
      setAuthLoading(false);
    }
  };

  useEffect(() => {

    initializeUser();
  }, []);


  useEffect(() => {
    if (!authLoading) {
      if (user && user.id) {
        const allowedPaths = ['/home', '/home/actionflow', '/upload'];
        const documentPathPattern = /^\/home\/document/;

        if (!allowedPaths.includes(location.pathname) && !documentPathPattern.test(location.pathname)) {
          navigate('/home');
        }
      } 
    }
  }, [location, navigate, user, authLoading]);

  useEffect(() => {
    if (location.pathname === '/home' && user && user.id && !loading) {
      fetchDocuments(user.id);
    }
  }, [location, user, loading]);

  const handleVerifySuccess = async (email) => {
    setVerifiedEmail(email);
  };

  const handleBackToHome = useCallback(async (newDocumentId) => {
    setShowUploadModal(false);
    navigate('/home');
}, [navigate]);

  const fetchDocuments = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/${userId}`);
      
      setDocuments(response.data.map(doc => {
        // If doc.actions is a raw JSON string with formatting artifacts, clean and parse it
        let actions = doc.actions;
        if (typeof actions === 'string') {
          actions = actions.replace(/```json|```/g, '').trim();
          try {
            actions = JSON.parse(actions);
          } catch (error) {
            console.error("Invalid JSON format in actions field:", error);
            actions = []; // Default to an empty array if parsing fails
          }
        }
  
        return {
          ...doc,
          id: doc.id,
          uploaded_at: new Date(doc.uploaded_at),
          actions: actions,
        };
      }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setDocuments([]);
      } else {
        console.error('Error fetching documents:', error);
      }
    }
  };
  

  const processFile = useCallback(async (file) => {
    const newDocument = {
      doc_name: file.name,
      uploaded_at: new Date().toISOString(),
      loading: true,
    };

    setDocuments((prevDocs) => [...prevDocs, newDocument]);
    try {
      const result = await mammoth.extractRawText({ arrayBuffer: await file.arrayBuffer() });
      setDocText(result.value);
      const uniqueFileName = `${uuidv4()}-${encodeURIComponent(file.name)}`;
      const preSignedUrlResult = await axios.post(
        `${process.env.REACT_APP_API_URL}/generate-presigned-url`,
        { file_name: uniqueFileName },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const uploadSuccess = await uploadFileToGCS(file, preSignedUrlResult.data.url);
      if (!uploadSuccess) throw new Error('Upload to MinIO failed');

      const documentData = {
        bucket_asset_name: uniqueFileName,
        doc_name: file.name,
        doc_summary: "contract",
        owner_id: user.id,
        uploaded_at: new Date().toISOString(),
        actions: "",
        status: "template"
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents/`, documentData);
      const newDocumentId = response.data.id;

      setDocuments((prevDocs) => prevDocs.map(doc =>
        doc.doc_name === file.name && doc.uploaded_at === newDocument.uploaded_at
          ? { ...doc, id: newDocumentId, bucket_asset_name: uniqueFileName, doc_summary: "contract", actions: [], loading: false }
          : doc
      ));
      handleBackToHome(newDocumentId);
    } catch (error) {
      console.error('Error processing text:', error);
      alert('Error processing text!');
      setDocuments((prevDocs) => prevDocs.filter(doc => doc.doc_name !== file.name || doc.uploaded_at !== newDocument.uploaded_at));
    } finally {
    }
  }, [user, handleBackToHome]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setFileRejections(rejectedFiles);
    if (acceptedFiles.length === 0) {
      alert('Invalid file type. Please upload a .docx file.');
      return;
    }
    const file = acceptedFiles[0];
    setFile(file);
    setShowUploadModal(false);
    processFile(file);
  }, [processFile]);

  const handleUploadDocument = () => {
    setShowUploadModal(true);
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('user');
      localStorage.removeItem('email');
      localStorage.removeItem('idToken');
      localStorage.removeItem('actions');
      localStorage.removeItem('selectedAssetId');
      localStorage.removeItem('selectedDocId');
      localStorage.removeItem('displayName');
      setUser(null);
      navigate('/home');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (authLoading) {
    return <Loading />;
  }

  return (
    <div className="background-container">
      {loading && (
        <div class="loading">
          <Loading />
        </div>
      )}
      <BlurContainer blurred={showUploadModal}>
        <StyledContainer>
          <Routes>
            <Route path="/" element={<Landing setLoading={setLoading} />} />
            <Route path="/signup" element={<SignUp setLoading={setLoading} />} />
            <Route path="/verify-email" element={<EmailVerification onVerifySuccess={handleVerifySuccess} />} />
            <Route path="/user-create" element={<UserCreate email={verifiedEmail} onSignupSuccess={handleLoginSuccess} />} />
            <Route path="/login" element={<LoginScreen onLoginSuccess={handleLoginSuccess} setLoading={setLoading} />} />
            <Route path="/home/*" element={
              <PrivateRoute user={user}>
                <HomeScreen
                  documents={documents}
                  docText={docText}
                  setDocuments={setDocuments}
                  onUploadClick={handleUploadDocument}
                  onLogoutClick={handleLogout}
                  onHomeClick={handleBackToHome}
                  user={user}
                />
              </PrivateRoute>
            } />
            <Route path="*" element={<Navigate to="/" />} />

          </Routes>
        </StyledContainer>
      </BlurContainer>
      {showUploadModal && (
        <>
          <Backdrop open={true} style={{ zIndex: 2, color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleBackToHome} />
          <ModalWrapper onClick={(e) => e.stopPropagation()}>
            <UploadScreen
              file={file}
              onDrop={onDrop}
              loading={loading}
              fileRejections={fileRejections}
            />
          </ModalWrapper>
        </>
      )}
    </div>
  );
}

export default Controller;
