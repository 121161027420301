import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Snackbar, Typography, useMediaQuery } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import sadDogImage from '../assets/sad-dog.webp';
import DocumentViewerScreen from './DocumentViewerScreen';
import Footer from './Footer';
import HomeScreenDocumentListItem from './HomeScreenDocumentList';
import Sidebar from './Sidebar';
import ToDoListScreen from './ToDoListScreen';

const StyledPaper = styled(Paper)(({ blurred }) => ({
  filter: blurred ? 'blur(5px)' : 'none',
  display: 'flex',
  width: '100vw',
  height: '100vh',
  borderRadius: '0',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
}));

const TopContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '16px',
  borderBottom: '2px solid #ff914d',
  alignItems: 'center',
  position: 'relative',
  textOverflow: 'ellipsis',
});

const ContentContainer = styled(Box)({
  flex: '1 1 auto',
  padding: '32px',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
});

const HomeScreen = ({ blurred, documents, docText, setDocuments, onUploadClick, onLogoutClick, onHomeClick, user, tempAccess }) => {
  const isSmallScreen = useMediaQuery('(max-width: 800px)');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuDocIndex, setMenuDocIndex] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deletingDocument, setDeletingDocument] = useState(false);
  const [actions, setActions] = useState([]);
  const [selectedDocId, setSelectedDocId] = useState(localStorage.getItem('selectedDocId') || '');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();

  const onActionClick = () => {
    const signedActions = documents
      .filter(doc => doc.status === 'signed') 
      .reduce((acc, doc) => acc.concat(doc.actions), []);

    setActions(signedActions);
    localStorage.setItem('actions', JSON.stringify(signedActions));
    navigate('/home/actionflow');
  };

  useEffect(() => {
    const savedActions = localStorage.getItem('actions');
    if (savedActions) {
      setActions(JSON.parse(savedActions));
    }
  }, []); 


  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogoutModal = (skipDialog = false) => {
    if (skipDialog === true) {
      onLogoutClick();
    } else {
      setLogoutDialogOpen(true);
    }
  };
  
  const handleConfirmLogout = () => {
    setLogoutDialogOpen(false);
    onLogoutClick();
  };

  const handleCancelLogout = () => {
    setLogoutDialogOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }
    return `Uploaded ${Math.floor((new Date() - date) / (1000 * 60 * 60 * 24))} days ago`;
  };

  const onDocumentClick = (doc) => {
    setTimeout(() => {
      if (!deletingDocument) {
        setActions(doc.actions);
        localStorage.setItem('selectedAssetId', doc.bucket_asset_name);
        localStorage.setItem('selectedDocId', doc.id);
        setSelectedDocId(doc.id);
        navigate(`/home/document/${doc.bucket_asset_name}`);
      }
    }, 300);
  };

  const handleMenuClick = (event, index, documentId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
    setMenuDocIndex(index);
    setSelectedDocId(documentId);
  };

  const handleCreateDraft = async () => {
    console.log("Creating draft from template...");
  };

  const handleDownload = async (event, fileName) => {
    event.stopPropagation();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate-download-url`, { file_name: fileName, temp_status: tempAccess }, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      const downloadUrl = response.data.url;
      window.location.href = downloadUrl;
    } catch (error) {
      console.error("Error generating download URL:", error);
      alert("Failed to generate download URL. Please try again.");
    }
  };

  const handleMenuClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
    setIsMenuOpen(false);
    setMenuDocIndex(null);
    setSelectedDocId(null);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    setConfirmDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setConfirmDeleteDialogOpen(false);
    setIsDeleting(true);
    setDeletingDocument(true);
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/documents/?user_id=${user.id}&doc_id=${selectedDocId}`);
      setDocuments((prevDocs) => prevDocs.filter(doc => doc.id !== selectedDocId));
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting document:", error);
      alert("Failed to delete document. Please try again.");
    } finally {
      setAnchorEl(null);
      setMenuDocIndex(null);
      setSelectedDocId(null);
      setDeletingDocument(false);
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setConfirmDeleteDialogOpen(false);
  };

  const handleDocumentClick = (doc) => {
    if (!isDeleting) {
      onDocumentClick(doc);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <StyledPaper blurred={blurred ? 'true' : undefined}>
        {(!isSmallScreen && sidebarOpen && !tempAccess) && (
          <Sidebar onHomeClick={onHomeClick} onActionClick={onActionClick} open={sidebarOpen} />
        )}
        <ContentContainer>
          {!tempAccess ? <TopContainer>
            <Box display="flex" alignItems="center" >
              <IconButton onClick={toggleDrawer}>
              <MenuIcon />
              </IconButton>
              <Typography variant="subtitle1" component="h1" gutterBottom>
                Welcome back, <Typography variant="subtitle1" component="span" sx={{ fontWeight: 'bold' }}>{user.displayName}</Typography>.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Button variant="text" color="inherit" onClick={onUploadClick}>
                Upload Document
              </Button>
              <Button variant="text" color="inherit" onClick={handleLogoutModal}>
                Logout
              </Button>
            </Box>
          </TopContainer> : null}

          <Routes>
            <Route path='/' element={
              documents.length === 0 ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" sx={{
                  paddingTop: isSmallScreen ? '50px' : '0',  // Add padding on top for small screens
                  overflow: 'hidden'
                }}>
                  <img src={sadDogImage} alt="Sad Dog" style={{ width: '300px', height: 'auto', marginBottom: '16px', marginTop: '50px' }} />
                  <Typography variant="h6" gutterBottom>
                    Oops, seems like you haven't uploaded any contracts yet.
                  </Typography>
                  <Typography variant="body1">
                    Upload contracts to make this puppy happy!
                  </Typography>
                </Box>
              ) : (
                <>
                  <HomeScreenDocumentListItem headerName="Template" statusFilter={(doc) => doc.status === 'template' || doc.status === null || doc.status === undefined || doc.status === ''} documents={documents} handleDocumentClick={handleDocumentClick} handleDownload={handleDownload} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleDelete={handleDelete} formatDate={formatDate} anchorEl={anchorEl} menuDocIndex={menuDocIndex} isMenuOpen={isMenuOpen} handleCreateDraft={handleCreateDraft}/>
                  <HomeScreenDocumentListItem headerName="Draft" statusFilter={(doc) => doc.status === 'draft'} documents={documents} handleDocumentClick={handleDocumentClick} handleDownload={handleDownload} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleDelete={handleDelete} formatDate={formatDate} anchorEl={anchorEl} menuDocIndex={menuDocIndex}isMenuOpen={isMenuOpen} handleCreateDraft={handleCreateDraft}/>
                  <HomeScreenDocumentListItem headerName="Pending" statusFilter={(doc) => doc.status === 'pending'} documents={documents} handleDocumentClick={handleDocumentClick} handleDownload={handleDownload} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleDelete={handleDelete} formatDate={formatDate} anchorEl={anchorEl} menuDocIndex={menuDocIndex} isMenuOpen={isMenuOpen} handleCreateDraft={handleCreateDraft}/>
                  <HomeScreenDocumentListItem headerName="Signed" statusFilter={(doc) => doc.status === 'signed'} documents={documents} handleDocumentClick={handleDocumentClick} handleDownload={handleDownload} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleDelete={handleDelete} formatDate={formatDate} anchorEl={anchorEl} menuDocIndex={menuDocIndex} isMenuOpen={isMenuOpen} handleCreateDraft={handleCreateDraft}/>
                  <HomeScreenDocumentListItem headerName="External" statusFilter={(doc) => doc.status === 'external'} documents={documents} handleDocumentClick={handleDocumentClick} handleDownload={handleDownload} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleDelete={handleDelete} formatDate={formatDate} anchorEl={anchorEl} menuDocIndex={menuDocIndex} isMenuOpen={isMenuOpen} handleCreateDraft={handleCreateDraft}/>
                </>
              )} />
            <Route path="/actionflow" element={<ToDoListScreen tasks={actions} />} />
            <Route path="/document/:documentName" element={<DocumentViewerScreen userEmail={user.email} docId={selectedDocId} docText={docText} handleLogout={handleLogoutModal} user={user} tempAccess={tempAccess}/>} />
          </Routes>
        </ContentContainer>
        <Dialog open={logoutDialogOpen} onClose={handleCancelLogout}>
          <DialogTitle>Confirm Logout</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelLogout} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmLogout} color="primary" autoFocus>
              Logout
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmDeleteDialogOpen}
          onClose={handleCancelDelete}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this document? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Document deleted successfully.
          </MuiAlert>
        </Snackbar>
      </StyledPaper>
      <Footer />
    </>
  );
};

export default HomeScreen;
