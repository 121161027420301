import GoogleIcon from '@mui/icons-material/Google';
import { Box, TextField, Typography } from '@mui/material';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import StyledButton from './common/Button';
import Footer from './Footer';
import { handleEmailLogin, handleGoogleLogin } from './Login';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const LoginScreen = ({ setLoading, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const handleEmailLoginClick = async () => {
    setLoading(true);
    try {
      await handleEmailLogin(navigate, email, password, { onSuccess: onLoginSuccess });
      const from = location.state?.from || '/home'; // Redirect to the intended path or default
      navigate(from);
    } catch (error) {
      console.error("Email login failed", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLoginClick = async () => {
    setLoading(true);
    try {
      await handleGoogleLogin(auth, { onSuccess: onLoginSuccess });
      const from = location.state?.from || '/home'; // Redirect to the intended path or default
      navigate(from);
    } catch (error) {
      console.error("Google login failed", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography variant="h4" mb={2}>
          Login
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2, width: '300px' }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 2, width: '300px' }}
        />
        <StyledButton
          variant="contained"
          disabled={!email || !password}
          onClick={handleEmailLoginClick}
          sx={{ mb: 2 }}
        >
          Sign In
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={handleGoogleLoginClick}
          sx={{ mb: 2 }}
          startIcon={<GoogleIcon />}
        >
          Continue with Google
        </StyledButton>
        <Typography variant="body1" mb={2}>
          Don't have an account?{' '}
          <Link
            to="/signup"
            sx={{
              color: '#000',
              textTransform: 'none',
              '&:hover': {
                color: '#888',
                backgroundColor: 'transparent',
              },
            }}
          >
            Sign up now
          </Link>
        </Typography>
      </Box>
      <Footer />
    </>
  );
};

export default LoginScreen;
