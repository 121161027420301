import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children, user }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isTempAccess = searchParams.get("temp") === "true";
  const signToken = searchParams.get("signToken");

  const [tempUser, setTempUser] = useState(null);
  const [loading, setLoading] = useState(isTempAccess); // Track loading status if temp access is true

  useEffect(() => {
    if (isTempAccess && signToken) {
      const fetchTempUser = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/token/get-user`, {
            params: {
              temp_status: true,
            },
            headers: {
              'Authorization': `Bearer ${signToken}`,
            },
          });
          setTempUser(response.data);
        } catch (error) {
          console.error("Error fetching temporary user data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchTempUser();
    }
  }, [isTempAccess, signToken]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while fetching user data
  }

  if (!user || !user.id) {
    if (isTempAccess && tempUser) {
      return React.cloneElement(children, { user: tempUser, tempAccess: true });
    } else {
      return <Navigate to="/login" state={{ from: location }} />;
    }
  }

  return children;
};

export default PrivateRoute;
