import { Button } from "@mui/material";
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
    backgroundColor: "#ff8f81",
    color: "black",
    marginBottom: "16px", 
    "&:hover": {
        backgroundColor: "#ff7a6b",
    },
    maxHeight: "5vh",
});

export default StyledButton;