import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Card, CardContent, CardHeader, IconButton, List, ListItem, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import React, { useState } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const ScrollableListContainer = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  marginTop: '16px',
});

const CustomDateTypography = styled(Typography)({
  border: 'none',
  outline: 'none',
  background: 'none',
  fontSize: '22px',
  cursor: 'pointer',
  textAlign: 'center',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const CustomTimelineElement = styled(VerticalTimelineElement)({
  '.vertical-timeline-element-content': {
    background: 'transparent !important',
    boxShadow: 'none !important',
    border: 'none !important',
  }
});

const ToDoListScreen = ({ tasks = [] }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (days) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + days);
    setSelectedDate(newDate);
  };

  const recognizedStatuses = ['ongoing', 'urgent']; // Define recognized statuses
  tasks = tasks.filter(task => task !== undefined);
  const taskGroups = tasks.reduce((groups, task) => {
    const group = recognizedStatuses.includes(task.status) ? task.status : 'Ongoing';
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(task);
    return groups;
  }, {});

  return (
    <Paper
      style={{
        padding: '32px',
        textAlign: 'center',
        borderRadius: '0',       // Remove border-radius
        boxShadow: 'none',       // Remove box-shadow
        border: 'none',          // Remove border
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <IconButton onClick={() => handleDateChange(-1)}>
          <ArrowBackIosIcon />
        </IconButton>
        <CustomDateTypography variant="h6">
          {format(selectedDate, 'dd, MMM, yyyy')}
        </CustomDateTypography>
        <IconButton onClick={() => handleDateChange(1)}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      <ScrollableListContainer>
        <VerticalTimeline layout="1-column-left" lineColor="#ff914d">
          {Object.keys(taskGroups).map((group, index) => (
            <CustomTimelineElement
            key={index}
            iconStyle={{ background: '#ff914d', color: '#fff' }}
            position="left"
          >
              <Box sx={{ marginBottom: 4, paddingLeft: 5}}>
                <Typography variant="h6" component="div" sx={{ textAlign: 'left', fontWeight: 'bold', marginTop: -2 }}>
                  {group}
                </Typography>
                <List>
                  {taskGroups[group].map((task, idx) => (
                    <ListItem key={idx} sx={{ paddingLeft: 0, paddingRight: 0}}>
                      <Card variant="outlined" sx={{ width: '100%' }}>
                        <CardHeader
                          title={task.action}
                          subheader={task.responsible_party}
                          avatar={<Box component="span" sx={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: '#888' }} />}
                        />
                        <CardContent>
                          <Box display="flex" alignItems="center" sx={{ justifyContent: 'space-between' }}>
                            <Box display="flex" alignItems="center" >
                              <AccessTimeIcon sx={{ fontSize: 20, marginRight: 0.5, marginTop: 2.25 }} />
                              <Typography variant="body2">{task.duration || 'N/A'}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <CalendarTodayIcon sx={{ fontSize: 18, marginRight: 0.5, marginTop: 2.25 }} />
                              <Typography variant="body2">{task.trigger || task.deadline || 'N/A'}</Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </CustomTimelineElement>
          ))}
        </VerticalTimeline>
      </ScrollableListContainer>
    </Paper>
  );
};

export default ToDoListScreen;
