import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useDropzone } from 'react-dropzone';

const StyledPaper = styled(Paper)({
  padding: '32px',
  textAlign: 'center',
  color: '#333',
  maxWidth: '400px',
  width: '100%',
  borderRadius: '16px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'white',
});

const DropzoneBox = styled(Box)(({ disabled }) => ({
  border: '2px dashed #ddd',
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
  cursor: disabled ? 'not-allowed' : 'pointer',
  pointerEvents: disabled ? 'none' : 'auto',
  backgroundColor: '#f5f5f5',
}));

const UploadScreen = ({ file, onDrop, loading, fileRejections }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    disabled: loading,
  });

  return (
    <StyledPaper>
      {/* <Typography variant="h4" component="h1" gutterBottom>
        Upload Document
      </Typography> */}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <DropzoneBox disabled={loading}>
          <CloudUploadIcon fontSize="large" />
          <Typography variant="body1" gutterBottom>
            Drag and drop a file here, or click to browse computer
          </Typography>
        </DropzoneBox>
        <Box mt={2}></Box>
        <Typography variant="body3" gutterBottom>
            Supported extensions: .docx
        </Typography>
      </div>
      {fileRejections.length > 0 && (
        <Typography color="error">
          Invalid file type. Please upload a .docx file.
        </Typography>
      )}
    </StyledPaper>
  );
};

export default UploadScreen;
