import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: 16,
    // h3: {
    //   fontSize: '2rem',
    //   '@media (min-width:600px)': {
    //     fontSize: '2rem',
    //   },
    //   '@media (min-width:960px)': {
    //     fontSize: '2.5rem',
    //   },
    // },
    // body1: {
    //   fontSize: '1rem',
    //   '@media (min-width:600px)': {
    //     fontSize: '0.8rem',
    //   },
    //   '@media (min-width:960px)': {
    //     fontSize: '1rem',
    //   },
    // },
    // body2: {
    //   fontSize: '.8rem',
    //   '@media (min-width:600px)': {
    //     fontSize: '.5rem',
    //   },
    //   '@media (min-width:960px)': {
    //     fontSize: '0.75rem',
    //   },
    // },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          fontSize: '1rem',
          padding: '12px 24px',
          textTransform: 'none',
          borderRadius: '8px',
          transition: 'transform 0.2s',
          '&:hover': {
            backgroundColor: '#020024',
            transform: 'scale(1.05)',
          },
          '@media (min-width:600px)': {
            fontSize: '1.2rem',
            padding: '14px 28px',
          },
          '@media (min-width:960px)': {
            fontSize: '1.5rem',
            padding: '16px 32px',
          },
        },
      },
    },
  },
});

export default theme;