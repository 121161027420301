import { Box, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import StyledButton from './common/Button';
import Footer from './Footer';

const SignUp = ({setLoading}) => {
  const [email, setEmail] = useState('');
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    // Basic email validation regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSignUp = async () => {
    // Reset error message
    setError('');

    // Validate email
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      setLoading(true);
      // Call the FastAPI endpoint to send verification email

      await axios.post(`${process.env.REACT_APP_API_URL}/users/`, {
        first_name: "",
        last_name: "",
        email: email,
      });

      // Simulate successful sign-up
      setIsSignedUp(true);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 409) {
        setLoading(false);
        setError('Email already exists.');
        return;
      } else {
        setLoading(false);
        setError('Failed to send verification email. Please try again.');
      };
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        {!isSignedUp ? (
          <>
            <Typography variant="h4" mb={2}>
              Sign Up
            </Typography>
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2, width: '300px' }}
              error={!!error}
              helperText={error}
            />
            <StyledButton
              variant="contained"
              disabled={!email}
              onClick={handleSignUp}
              sx={{ mb: 2 }}
            >
              Sign Up
            </StyledButton>
            <Typography variant="body1" mb={2}>
              Already have an account?{' '}
              <Link
                to="/login"
                sx={{
                  color: '#000',
                  textTransform: 'none',
                  '&:hover': {
                    color: '#888',
                    backgroundColor: 'transparent',
                  },
                }}
              >
                Log in
              </Link>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h5" mb={2}>
              Sign-up successful!
            </Typography>
            <Typography variant="body1" mb={2}>
              Please check your email for verification.
            </Typography>
            <Link
              to="/"
              sx={{
                color: '#000',
                textTransform: 'none',
                '&:hover': {
                  color: '#888',
                  backgroundColor: 'transparent',
                },
              }}
            >
              Return to Home
            </Link>
          </>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default SignUp;
