// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
// import './index.css';
import reportWebVitals from './reportWebVitals';
// import './styles.css'; // Ensure styles are imported
import './styles/index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
