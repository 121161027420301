// Loading.js
import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const Loading = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh" color="red">
    <CircularProgress sx={{color: "#ff914d"}}/>
  </Box>
);

export default Loading;
