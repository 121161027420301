import axios from 'axios'; // Axios for API requests
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // To get the query string

const EmailVerification = ({ onVerifySuccess }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const location = useLocation(); // Get the location object to extract the query string
  const navigate = useNavigate();

  const verifyCode = useCallback(async (code) => {
    try {
      // Clear any previous error or success messages
      setError('');
      setSuccess('');

      // Make the API call to verify the code
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/verify-email?verification_token=${code}`,
        {}
      );

      // Check response status (assuming success if status is 200 or similar)
      if (response.status === 200) {
        setSuccess('Email verified successfully!');
        setTimeout(() => {
          onVerifySuccess(response.data.user.email);
          navigate('/user-create'); // Replace '/login' with the desired destination after successful email verification
        }, 3000);
      } else {
        setError('Invalid verification code');
      }
    } catch (err) {
      // Handle errors, such as network errors or API returning an error status
      setError(err.response?.data?.message || 'Something went wrong. Please try again.');
    }
  }, [navigate, onVerifySuccess]);

  useEffect(() => {
    // Extract code from URL query string
    const queryParams = new URLSearchParams(location.search);
    const codeFromUrl = queryParams.get('code');

    if (codeFromUrl) {
      // If code exists in URL, attempt verification automatically
      verifyCode(codeFromUrl);
    }
  }, [location.search, verifyCode]);

  const handleChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!verificationCode) {
      setError('Verification code is required');
      return;
    }
    verifyCode(verificationCode);
  };


  return (
    <div>
      <h2>Email Verification</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
      {!success && !location.search.includes('code=') && (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Verification Code:</label>
            <input
              type="text"
              value={verificationCode}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Verify</button>
        </form>
      )}
    </div>
  );
};

export default EmailVerification;
